import './ArticleModal.css';

function ResultCard({ url, aspect, response }) {
    const getCardHeaderColor = (aspect) => {
        switch(aspect) {
          case 'economy': 
            return "rgba(102, 194, 165, 0.4)";
          case 'equality & justice':
            return "rgba(252, 141, 98, 0.4)";
          case 'health & well-being':
            return "rgba(141, 160, 203, 0.4)";
          case 'access to information & discourse':
            return "rgba(231, 138, 195, 0.4)";  
          case 'politics':
            return "rgba(166, 216, 84, 0.4)";
          case 'power dynamics':
            return "rgba(255, 217, 47, 0.4)";
          case 'security & privacy':
            return "rgba(229, 196, 148, 0.4)";
          case 'social norms & relationship':
            return "rgba(179, 179, 179, 0.4)";
          case 'user experience':
            return "rgba(76, 175, 80, 0.4)";
          case 'environment & sustainability':
            return "rgba(123, 123, 231, 0.4)";
          default:
            return 'bg-secondary';  // Default color for unknown domains
        }
      };

    
      const boldFirstLetter = (text) => {
        return text.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        // return text.charAt(0).toUpperCase() + text.slice(1);
      };

      const resultCardHeaderColor = getCardHeaderColor(aspect);
    if (!response.title_relevant) {
        return (
            <div className="alert alert-warning" role="alert">
                Sorry, but the <strong>title</strong> of the article doesn't seem relevant to the topic of undesirable consequences in this domain. (Note that the decision is generated by GPT-3.)
            </div>
        );
    } else if (!response.content_relevant) {
        return (
            <div className="alert alert-warning" role="alert">
                Sorry, but the <strong>content</strong> of the article doesn't seem relevant to the topic of undesirable consequences in this domain. (Note that the decision is generated by GPT-3.)
            </div>
        );
    } else {
        return (
        <div id="card-trial" className="col-12 mb-3">
            <div className="card shadow uccards" style={{backgroundColor: resultCardHeaderColor}}>
            <div id="cardhead" className="card-header" style={{backgroundColor: resultCardHeaderColor}}>
                <div>
                Aspect: {boldFirstLetter(response.aspect)}
                </div>
            </div>
            <div className="card-body">
                <p className="card-text"><strong>Summary</strong>: {response.summary} (Note that the summary is generated by GPT-3.)</p>
                <a style={{textDecoration: 'none'}} href={url} target="_blank" rel="noreferrer">
                <h6 className="card-title">{response.title}</h6>
                </a>
            </div>
            </div>
            <br />
            <div className="alert alert-success" role="alert">
            Please note that this card will <strong>NOT</strong> be automatically added to the database. However, we have received the input about this article and will use it to improve the catalog upon moderation. The information is received anonymously.
            </div>
        </div>
        );
    }
}

export default ResultCard;